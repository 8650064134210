import React from 'react';

class ItemDepoimento extends React.Component{
    constructor(props){
        super(props)

    }

    render(){

        const deletedDepoStyle={
            display:'none'
        }

        const notDeletedDepoStyle={
            color:'#000'
        }

        var depoDeletado = this.props.depoimentos.deletado;

        return(<div className="texto depoimento__texto" style={depoDeletado?
            deletedDepoStyle:notDeletedDepoStyle}>
        <p>{this.props.depoimentos.texto}</p>
        <span>{this.props.depoimentos.cliente} <br></br> 
        {this.props.depoimentos.ocupacao}</span>
    </div>)
    }
}


export default ItemDepoimento