var config = { /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
    apiKey: "AIzaSyDnAgHANO_1ex8daRKv9egHtMSM5tmSLYY",
    authDomain: "rolfing-15325.firebaseapp.com",
    databaseURL: "https://rolfing-15325.firebaseio.com",
    projectId: "rolfing-15325",
    storageBucket: "rolfing-15325.appspot.com",
    messagingSenderId: "587392369443",
    appId: "1:587392369443:web:95f9a92832c5729e7a4296"
    };
firebase.initializeApp(config);



export default firebase;