import React from "react"
import ReactDOM from "react-dom"
import DepoimentosMain from "./components/DepoimentosMain"
import firebase from './fire.js'


  var db = firebase.firestore()
  db.collection('depoimentos').get()
  .then((snap)=>{
    let lista=[]
    snap.docs.forEach(doc=>{
      let obj = doc.data()
      lista.push(obj)
    })

    localStorage.setItem('listaDepoimentos',JSON.stringify(lista))
    console.log(lista)
})

ReactDOM.render(<DepoimentosMain/>,document.getElementById('DepoimentosApp'))


