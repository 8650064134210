import React from 'react';
import ItemDepoimento from './ItemDepoimento';

class DepoimentosMain extends React.Component{
    constructor(){
        super();
        this.state={
            depoimentos:[]
        }
    }


componentDidMount(){
  let self = this;

  
  self.setState({
    depoimentos:JSON.parse(localStorage.getItem('listaDepoimentos'))
  })
}

render(){
    return(
        this.state.depoimentos.map((item)=>
        <ItemDepoimento depoimentos={item}/>)
    )
}

}

export default DepoimentosMain